import React from 'react'


export default function Error404() {
  return (
    <div><h1>Error404</h1>
        <p>La pàgina que busques no existeix</p>
        <a href="/">TORNA AL HOME</a>
    </div>
  )
}